import { Alert, FormControlLabel, LinearProgress, Radio, RadioGroup } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { apiRoutes, authetictedAxios } from "../../utils/api";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { CHECKOUT_CONTEXT } from "./Checkout";
export function OrderDetails() {
    const { id } = useParams()
    const { isLoading, data, isError, mutate } = useMutation(apiRoutes.getCartInfoFunc(id, 0), async (value) => {
        const res = await authetictedAxios().get(apiRoutes.getCartInfoFunc(id, value))
        if (res.data) {
            return res.data
        }
    }, {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        cacheTime: 0
    })

    const { stage, setFieldValue } = useContext(CHECKOUT_CONTEXT)
    useEffect(() => {
        mutate(stage)
    }, [stage,mutate])

    const handleShippingOption = (e) => {
        setFieldValue("shippingType",e.target.value)
    }


    return (
        <>
            {isLoading && <LinearProgress color="info" />}
            {isError && <Alert severity="error" color="error">There was an error fetching cart detials</Alert>}
            {data && <div className="part-2">
                <div className="checkout-cart">
                    <div className="title-head">YOUR ORDER</div>
                    <table>
                        <tr>
                            <td>Product</td>
                            <td>Size</td>
                            <td>Items</td>
                            <td>Cost</td>
                        </tr>
                        <tbody>
                            {data && data.cartItems.map((item) => {
                                return <tr>
                                    <td>{item.item.user_moded_glb.name}</td>
                                    <td>{item.item.variant}</td>
                                    <td>{item.item.count}</td>
                                    <td>{item.item.count * item.merchizeInfo.retail_price}{" "}{data && data.currency}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <hr></hr>
                    <div className="final-cost">
                        <div>Subtotal</div>
                        <div>{data && data.total}{" "}{data && data.currency}</div>
                    </div>
                    <div className="final-cost no-flex">
                        <div>Choose Shipping Type</div>
                        {stage === 0 && <div className="table">
                            <Alert severity="info" color="info">Shipping info will be calculated after entering location details.</Alert>
                        </div>}
                        {
                            data && stage === 1 && data.shipmentInfo?.standard && (
                                <table className="table">
                                    <tr>
                                        <td>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="radio-buttons-group"
                                                onChange={handleShippingOption}
                                            >
                                                {data.shipmentInfo.standard?.standard?.total_shipping && <FormControlLabel value="standard" control={<Radio />} label="Standard" />}
                                                {data.shipmentInfo.priority?.total_shipping && <FormControlLabel value="priority" control={<Radio />} label="Priority" />}
                                                {data.shipmentInfo.express?.total_shipping && <FormControlLabel value="express" control={<Radio />} label="Express" />}
                                                {data.shipmentInfo.economy?.total_shipping && <FormControlLabel value="economy" control={<Radio />} label="Economy" />}
                                            </RadioGroup>
                                        </td>
                                        <td>
                                            {data.shipmentInfo.standard?.standard?.total_shipping && <div className="price-for-shipping">{data.shipmentInfo.standard?.standard?.total_shipping}{" "}{data && data.currency}</div>}
                                            {data.shipmentInfo.priority?.total_shipping && <div className="price-for-shipping">{data.shipmentInfo.priority?.priority?.total_shipping}{" "}{data && data.currency}</div>}
                                            {data.shipmentInfo.express?.total_shipping && <div className="price-for-shipping">{data.shipmentInfo.express?.express?.total_shipping}{" "}{data && data.currency}</div>}
                                            {data.shipmentInfo.economy?.total_shipping && <div className="price-for-shipping">{data.shipmentInfo.economy?.economy?.total_shipping}{" "}{data && data.currency}</div>}
                                        </td>
                                    </tr>
                                </table>
                            )
                        }
                    </div>
                    <div className="final-cost">
                        <div>Total</div>
                        <div>{data && data.total}{" "}{data && data.currency}</div>
                    </div>
                    <div className="bottom-el">
                        <label>Your personal data will be used to process your order,
                            support your experience throughout this website, and for
                            other purposes described in our privacy policy.</label>
                        <div>
                            <button className="place-order" type={"submit"} >Choose Shipping Option</button>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}