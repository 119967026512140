import { useContext, useState } from "react";
import { PRODUCT_DETAILS_CONTEXT } from "./productDetails";
import { useDispatch } from "react-redux";
import { addCartItem } from "../../redux/cart/cartSlice";
import { v4 as uuid4 } from "uuid"
import { toast } from "react-toastify";

export function CartBtns() {
    const [count, setCount] = useState(1)
    const { selectedSize, data } = useContext(PRODUCT_DETAILS_CONTEXT)
    const dispatch = useDispatch()
    const cartInfo = {
        data,
        selectedSize,
        modId: data.user_moded_glb_by_pk.id,
        count,
        id: uuid4()
    }
    const handleAddToCart = () => {
        if (!selectedSize) {
            toast.error("Please select at least one size to add item to cart.")
        }
        if (selectedSize) {
            toast.success("The product has been added to your cart successfully.")
            dispatch(addCartItem(cartInfo))
        }
    }

    return <div className="btn-group">
        <div></div>
        <div className="cart-btns">
            <div className="count-btns">
                <div className="counter">
                    <div className="cont">
                        <svg width="18" height="2" viewBox="0 0 18 2" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setCount(count - 1)} style={{ cursor: "pointer" }}>
                            <path d="M1 1H17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div className="count-number">{count}</div>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setCount(count + 1)} style={{ cursor: "pointer" }}>
                            <path d="M9 1V17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 9H17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </div>
                <button className="add-to-cart" onClick={handleAddToCart}>Add To Cart</button>
            </div>
        </div>
    </div>;
}