import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./SingleMerchizeItem.module.scss";

export function SingleMerchizeItem({ count, title, retail_price, cta, onClick, thumbnails }) {
    return (
        <div className={styles.SingleMerchizeItem}>
            <div className="behind">
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={false}
                    interval={3000}
                    showIndicators={false}
                    className={styles.carouselContainer}
                    stopOnHover={true}
                >
                    {thumbnails.map((thumbnail, index) => (
                        <div key={index} className="single-slide" style={{
                            backgroundImage: `linear-gradient(2.76deg, rgba(31, 146, 253, 0.69) 9.43%, rgba(248, 247, 255, 0.332713) 42.96%, rgba(255, 255, 255, 0) 123.71%),url("${thumbnail}")`
                        }} >
                        </div>
                    ))}
                </Carousel>
            </div>
            <div className="top-layer">
                <div className="top">
                    <div className="numbering">{count + 1 < 10 ? `0${count + 1}` : count + 1}</div>
                </div>
                <div className="bottom">
                    <div className="product-name">{title}</div>
                    <div className="product-price">{process.env.REACT_APP_CURRENCY} {retail_price}</div>
                    <div className="buy-now-btn" role="button" onClick={onClick}>
                        {cta}
                    </div>
                </div>
            </div>
        </div>
    );
}
