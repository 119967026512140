import styles from "./singleModItem.module.scss"
import { useNavigate } from "react-router-dom"
import { appRoutes } from "../../../../utils/appRoutes";
import { useQuery } from "react-query";
import { apiRoutes, authetictedAxios } from "../../../../utils/api";
import { Alert, Skeleton } from "@mui/material";

export function SingleModItem({ id, item }) {

    const navigate = useNavigate()

    const handleProductTransfer = (id) => {
        navigate(appRoutes.productDetailsFunc(id))
    }

    const { isLoading, data, isError } = useQuery(apiRoutes.singleMerchizeProduct + item.id + item.updated_at, async () => {
        if (item.users_glb) {
            if (item.users_glb.linked_printify_blueprint){
                const res = await authetictedAxios().get(apiRoutes.singlePrintifyInfoFunc(item.users_glb.merchizeProductByLinkedPrintifyBlueprint.product_id))
                if(res){
                    return res.data
                }
            }
            const data = await authetictedAxios().get(apiRoutes.singleMerchizeProductFunc(item.users_glb.merchize_product.product_id))
            if (data) {
                return data.data
            }
        }
        return false
    }, {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        cacheTime: 0
    })

    if (isError) {
        return <><Alert severity="error" >There was error retrieving mod information</Alert></>
    }

    if (isLoading) {
        return <Skeleton height="42vh" sx={{ transform: "none", backgroundColor: "black", borderRadius: "0px" }}></Skeleton>
    }

    const navigateToGame = (id) => {
        navigate(appRoutes.chooseGameToTransferToFunc(id))
    }

    const handlePrice=()=>{
        const currency = process.env.REACT_APP_CURRENCY
        if(item.users_glb.linked_printify_blueprint){
            return `${currency} ${data.minPrice} - ${currency} ${data.maxPrice}`
        }
        else{
            return `${currency} ${data.retail_price}`
        }
    }

    return (
        <div className={styles.singleMod} key={id} style={{
            backgroundImage: `linear-gradient(2.76deg, rgba(0, 0, 0, 0.5) 100%, rgba(248, 247, 255, 0.332713) 42.96%),url("${item.placeholder_image_url}")`
        }} >
            <div className="top">
                <div className="numbering">{id + 1 < 10 ? `0${id + 1}` : id + 1}</div>
            </div>
            <div className="bottom">
                <div className="product-name">{item.name}</div>
                {data && <div className="product-price">{ } {handlePrice()}</div>}
                {data && <div className="export-btns">
                    <div className="buy-now-btn" onClick={() => handleProductTransfer(item.id)}>
                        Transfer to garment
                    </div>
                </div>}
                <div className="export-btns" style={{ marginTop: "15px" }}>
                    <div className="buy-now-btn" onClick={() => navigateToGame(item.id)}>
                        Transfer to game
                    </div>
                </div>
            </div>
        </div>
    );
}