import { useSelector } from "react-redux"

export const Cart = () => {
    const cartItems = useSelector((state) => state.cartSliceReducer.cartItemsCount)
    return <><svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.75 8L9.75 12V26C9.75 26.5304 9.96071 27.0391 10.3358 27.4142C10.7109 27.7893 11.2196 28 11.75 28H25.75C26.2804 28 26.7891 27.7893 27.1642 27.4142C27.5393 27.0391 27.75 26.5304 27.75 26V12L24.75 8H12.75Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.75 12H27.75" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.75 16C22.75 17.0609 22.3286 18.0783 21.5784 18.8284C20.8283 19.5786 19.8109 20 18.75 20C17.6891 20 16.6717 19.5786 15.9216 18.8284C15.1714 18.0783 14.75 17.0609 14.75 16" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="7.5" cy="7.5" r="7.5" fill="#FF0000" />
        <text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="sfProDisplayMedium" fontSize="12" letterSpacing="0em"><tspan x="4" y="11.3636">{cartItems}</tspan></text>
    </svg>
    </>
}