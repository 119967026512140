import { gql } from "@apollo/client";

export const DO_WE_HAVE_LINKED_PRODUCT = gql`
  query DO_WE_HAVE_LINKED_PRODUCT($id: uuid!) {
    users_glbs_by_pk(id: $id) {
      linked_merchize_product
    }
  }
`;


export const CURRENT_MATCH = gql`
  query CURRENT_MATCH($id: uuid!) {
    users_glbs_by_pk(id: $id) {
      pattern_match
      linked_printify_blueprint
    }
  }
`;