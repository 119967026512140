import React from 'react'
import Steps from '../../Steps'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoutes } from '../../../../utils/appRoutes'
import { apiRoutes, authetictedAxios } from '../../../../utils/api'
import { Alert, Backdrop, CircularProgress, Grid, LinearProgress } from '@mui/material'
import { SingleMerchizeItem } from '../../../../components/MerchizeProduct/SingleMerchizeItem'
import { useQuery, useMutation as useTanMutation } from 'react-query'
import { useQuery as useApolloClient } from '@apollo/client'
import { UPDATE_SELECTED_PRODUCT, UPDATE_SELECTED_PRODUCT_PRINTIFY } from '../../../../graphql/updateLinkedProduct.mutation'
import { useMutation } from '@apollo/client'
import RightPageDetailsNoLayout from '../../../../layout/RightPageDetailsNoLayout.layout'
import { DO_WE_HAVE_LINKED_PRODUCT } from '../../../../graphql/doWeHaveLinkedProduct.query'

export default function SelectProduct() {

  // handle back to glb upload
  const { id } = useParams()
  const navigate = useNavigate()
  const handleBack = () => {
    navigate(appRoutes.uploadGlBFunc(id))
  }

  //handle list of products api
  const { isLoading, data, isError } = useQuery(apiRoutes.merchizeProducts, async () => {
    const res = await authetictedAxios().get(apiRoutes.merchizeProducts)
    if (res) {
      return res.data
    }
  }, {
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  // handle list of printify products
  const { isLoading: loadingPrintify, data: dataPrintify, isError: errorPrintify } = useQuery(apiRoutes.printify, async () => {
    const res = await authetictedAxios().get(apiRoutes.printify)
    if (res) {
      return res.data
    }
  }, {
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  const [updateSelectedProduct, { loading, error }] = useMutation(UPDATE_SELECTED_PRODUCT)
  const [updateSelectedProductPrintify] = useMutation(UPDATE_SELECTED_PRODUCT_PRINTIFY)
  const { loading: loadingCurrent, data: currentData, error: currentError } = useApolloClient(DO_WE_HAVE_LINKED_PRODUCT, {
    variables: {
      id
    }
  })
  const handleNext = () => {
    navigate(appRoutes.uploadGlb + appRoutes.uploadGlbSubRoutes.matchGlbFunc(id))
  }

  const handleClick = async (id, product_id) => {
    const res = await updateSelectedProduct({
      variables: {
        id,
        linked_merchize_product: product_id
      }
    })
    if (res.data.update_users_glbs_by_pk?.linked_merchize_product) {
      handleNext()
    }
  }

  // create_product 
  const { isLoading: loadingUpdatePrintify, isError: loadingUpdatePrintifyError, mutate } = useTanMutation(apiRoutes.createProduct, async (productId) => {
    const productRes = await authetictedAxios().post(apiRoutes.createProductFunc(productId))
    if (productRes) {
      const res = await updateSelectedProductPrintify({
        variables: {
          id,
          linked_printify_product: productRes.data.id
        }
      })

      if (res.data.update_users_glbs_by_pk?.linked_printify_blueprint) {
        handleNext()
      }
    }

  })

  const handlePrintifyClick = async (id, product_id) => {
    mutate(product_id)
  }
  return (
    <>
      {console.log("teh laoding",loadingUpdatePrintify)}
      <div style={{ width: "100%" }}><Steps activeStep={1} handleBack={handleBack} handleNext={() => { }} /></div>
      <RightPageDetailsNoLayout title="Choose Product" subTitle="Choose a product related to the glb">
        {(isLoading || loadingCurrent || loadingPrintify) && <LinearProgress color="info"></LinearProgress>}
        {currentError && <Alert severity='error' sx={{ marginBottom: "20px" }}>There was an error loading recent error</Alert>}
        {(error || loadingUpdatePrintifyError) && <Alert severity='error' sx={{ marginBottom: "20px" }}>Error Linking product</Alert>}
        <Grid container spacing={2}>
          {data && currentData && dataPrintify && data.map((item, idx) => {
            return (
              <Grid item xs={3}>
                <SingleMerchizeItem
                  key={idx}
                  cta={currentData.users_glbs_by_pk.linked_merchize_product === item.hasura_info.id ? "Re order link" : "Link Product"}
                  count={idx}
                  onClick={() => { handleClick(id, item.hasura_info.id) }}
                  retail_price={item.retail_price}
                  thumbnails={[item.thumbnail]}
                  title={item.title}>
                </SingleMerchizeItem>
              </Grid>
            )
          })}
          {data && currentData && dataPrintify && dataPrintify.map((item, idx) => {
            return (
              <Grid item xs={3}>
                <SingleMerchizeItem
                  key={idx}
                  cta={"Link Product"}
                  count={idx + data.length}
                  onClick={() => { handlePrintifyClick(id, item.id) }}
                  retail_price={50}
                  thumbnails={item.images}
                  title={item.title}>
                </SingleMerchizeItem>
              </Grid>
            )
          })}
        </Grid>
        {(isError || errorPrintify) && <Alert severity='error'>There was an error loading products</Alert>}
        {(loading || loadingUpdatePrintify) && <Backdrop open={true} sx={{ zIndex: "100" }}>
          <CircularProgress color='info'></CircularProgress>
        </Backdrop>}
      </RightPageDetailsNoLayout>
    </>
  )
}
