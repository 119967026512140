import { gql } from "@apollo/client";

export const SINGLE_MOD_DETAILS = gql`
  query SINGLE_MOD_DETAILS($id: Int!) {
    user_moded_glb_by_pk(id: $id) {
      baked_pattern
      placeholder_image_url
      glb_url
      source_glb
      id
      name
    }
  }
`;

export const GLB_MERCHIZE_ID = gql`
  query GLB_MERCHIZE_ID($id: uuid!) {
    users_glbs_by_pk(id: $id) {
      merchize_product {
        product_id
      }
      merchizeProductByLinkedPrintifyBlueprint {
        product_id
      }
    }
  }
`;
